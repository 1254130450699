
.main-wrapper {
  height: 100%;
  padding: 30px 30px 0;
  .content {
    padding: 50px;
  }
  .next-btn {
    text-align: center;
  }
}
